import { ref, computed } from "vue"
import { simService } from "@/api"
import { unixToDateTimeString } from "@/js/general"
import { useSimTable } from "./use-sim-table"
import { useSimsStore } from "./use-sims-store"

export const BASIC_INFO_COLUMNS = [
  { name: "Last name", key: "name", sortable: false, formatter: (v) => v },
  { name: "Last description", key: "description", sortable: false, formatter: (v) => v },
  {
    name: "Created At",
    key: "history.createDate",
    sortable: false,
    formatter: unixToDateTimeString,
  },
]

export const useSimHistory = ({ id }) => {
  const simHistory = ref([])
  const lastSimState = ref(null)
  const isLoading = ref(false)
  const params = ref({
    page: 1,
    itemsPerPage: 25,
    search: "",
    sortBy: "",
    descending: false,
  })

  const { displayableColumns, createDisplayableColumns } = useSimTable()
  const { fetchOrgCustomFields } = useSimsStore()

  const meta = ref({})

  const updateMeta = (newMeta) => {
    meta.value = newMeta
  }

  const retrieveMeta = (resp) => {
    const { page, pageCount, perPage: itemsPerPage, totalCount, columns } = resp._metadata

    return {
      page,
      pageCount,
      itemsPerPage,
      totalCount,
      columns,
    }
  }

  const _prepareParams = (_params) => {
    // eslint-disable-next-line no-undef
    const _p = structuredClone(_params)
    if (_p.sortBy) {
      _p.sortBy = _p.sortBy.split(".").pop()
    }
    return _p
  }

  const updateParams = (newParams) => {
    params.value = newParams
    updateHistoryData(_prepareParams(params.value))
  }

  const updateHistoryData = async (_params, { triggerLoading = true } = {}) => {
    if (triggerLoading) {
      isLoading.value = true
    }

    try {
      const promises = [await simService.v2.getSimHistory(id, _params)]
      if (_params.page === 1) {
        promises.push(await simService.v2.getSim(id))
      }
      promises.push(fetchOrgCustomFields())

      const [historyResponse, sim] = await Promise.all(promises)

      if (sim) {
        lastSimState.value = sim
      }

      if (historyResponse.records && historyResponse.records.length > 0) {
        simHistory.value = historyResponse.records
        updateMeta(retrieveMeta(historyResponse))
      } else {
        simHistory.value = []
      }
    } catch (err) {
      lastSimState.value = null
      simHistory.value = []
      throw err
    } finally {
      isLoading.value = false
    }
  }

  const init = async () => {
    await updateHistoryData(_prepareParams(params.value))
    createDisplayableColumns(meta.value?.columns || [])
  }

  const simName = computed(() => {
    return lastSimState.value?.name || "Not found"
  })

  const history = computed(() => {
    if (params.value.page === 1) {
      const item = lastSimState.value ? [lastSimState.value] : []
      return [].concat(item, simHistory.value)
    }
    return simHistory.value
  })

  const visibleSimColumns = computed(() => displayableColumns.value.filter((c) => Boolean(c.name)))

  return {
    simHistory,
    history,
    lastSimState,
    simName,
    init,
    params,
    updateParams,
    meta,
    isLoading,
    visibleSimColumns,
  }
}
